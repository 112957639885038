import PropTypes from 'prop-types';
import { betTypes, paymentMethods, paymentStatus, sites } from 'trhub-utils';

export const userType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  uid: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired, // Empty string if not set
  email: PropTypes.string.isRequired,
  roles: PropTypes.array.isRequired,
});

export const detailedUserType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  mobile: PropTypes.string,
  customerNumber: PropTypes.number,
  address: PropTypes.shape({
    careOf: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
  }).isRequired,
});

export const orderType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  lineItems: PropTypes.array.isRequired,
  netPrice: PropTypes.number,
  totalVAT: PropTypes.number,
  totalPrice: PropTypes.number.isRequired,
  paymentMethod: PropTypes.oneOf(Object.keys(paymentMethods)),
  paymentStatus: PropTypes.oneOf(Object.keys(paymentStatus)).isRequired,
});

export const subscriptionType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    paymentInterval: PropTypes.number.isRequired,
  }),
  paymentMethod: PropTypes.oneOf(Object.keys(paymentMethods)).isRequired,
  startDate: PropTypes.string.isRequired,
  dueDate: PropTypes.string,
  endDate: PropTypes.string,
  unsubscribeDate: PropTypes.string,
  pauseStartDate: PropTypes.string,
  pauseEndDate: PropTypes.string,
  isDistributable: PropTypes.bool.isRequired,
});

export const subscriptionListItemType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
  }),
  paymentMethod: PropTypes.oneOf(Object.keys(paymentMethods)).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  unsubscribeDate: PropTypes.string,
});

export const ticketType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  site: PropTypes.oneOf(Object.keys(sites)).isRequired,
  betType: PropTypes.arrayOf(PropTypes.oneOf(betTypes)).isRequired,
  remaining: PropTypes.number.isRequired,
  punches: PropTypes.number.isRequired,
  usage: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      roundDate: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
    }),
  ),
});
